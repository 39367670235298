import React from 'react';
import Datalist from './citylist';
import {
  Input,
} from './style';
import Styles from './citytitle.module.css';
import {Link} from 'gatsby';

function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = typeof window !== 'undefined' && window.localStorage.getItem(key);
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    typeof window !== 'undefined' && window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}



const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
    }, [value]);
 
  return [value, setValue];
};

 
const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
    <div>
      <div className={Styles.cp_iptxt}>
        <input
         className={Styles.ef}
         value={value}
          type="text"
          placeholder="都市名を検索して選択"
          onChange={onChange} 
          autocomplete="on"
          list="keywords"
        />
        <label>都市名を入力 ＞選択 ＞リロード</label>
        <span className={Styles.focus_line}></span>
        <Datalist />
      </div>
    </div>
  );
};
 
export default App;